<template>
  <b-card title="Return Report">
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
      @hidden="resetNoData"
    >
      <strong v-if="returnToDownload.length <= 0 && noData === false">Loading data...</strong>
      <strong v-if="returnToDownload.length <= 0 && noData === true">Data from selected month range is not found, please change the month range</strong>
      <strong v-if="returnToDownload.length != 0">Loading data finished, total data is {{ returnToDownload.length }}</strong>
      <div>
        <strong v-if="returnToDownload.length != 0"> Progress {{ progressDownload }} %</strong>
      </div>
      <div style="display: table; margin: 0 auto;">
        <b-button :disabled="returnToDownload.length <= 0" variant="primary" style="margin-top: 20px">
          <download-excel :data="returnToDownload" :fields="active? filterNoReturnDate : filter" :name="'Return Report.xls'" class="download-border">Download</download-excel>
        </b-button>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-row>
        <b-col cols="6">
          <b-form-group label="From Month">
            <date-picker 
              v-model="fromMonth" 
              type="month" 
              format="MMMM YYYY" 
              :disabled-date="disableFromDate"
              style="width: 100%" 
              @change="updateToDateRange">
            </date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="To Month">
            <date-picker 
              v-model="toMonth" 
              type="month" 
              format="MMMM YYYY"
              style="width: 100%"
              :disabled-date="disableToDate">
            </date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-checkbox
            v-if="showCheckbox"
            style="margin-top: 25px;"
            id="checkbox-1"
            v-model="active"
            name="checkbox-1"
          >
          No Return Date
          </b-form-checkbox>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <b-button :disabled="fromMonth === null || toMonth === null" variant="primary" style="margin-top: 25px" @click="openDownloadModal">
            Download
          </b-button>
        </b-col>
      </b-row>
      <br>
    </b-col>
  </b-card>
</template>
  
  <script>
  import { mapActions, mapState, mapGetters } from 'vuex';
  import axios from '@/axios';
  import vSelect from 'vue-select';
  import {userAccess,dateFormat} from '@/utils/utils'
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  
  export default {
    components: {
      "date-picker": DatePicker,
      'v-select': vSelect,
    },
    name: 'barchart',
    props: {
      msg: String
    },
    data() {
      return {
        filter: {
          'Invoice Number' : {
            field: 'invoice',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'Return Date' : 'returnDate',
          'Customer Name 1' : 'customerName1',
          'Customer Name 2' : 'customerName2',
          'Customer Name 3' : 'customerName3',
          'Customer Name 4' : 'customerName4',
          'Customer Code' : {
            field: 'customerCode',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'DO Number' : {
            field: 'DOnumber',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'DO Date': 'Dodate',
          'Material':'material',
          'Quantity' : {
            field: 'quantity',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'SBU' :{
            field: 'phier',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'Return Reason' :'returnReason',
          'Return Status' :'returnStatus',
          'Return Number' :'flag',
        },
        filterNoReturnDate: {
          'Invoice Number' : {
            field: 'invoice',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'Customer Name 1' : 'customerName1',
          'Customer Name 2' : 'customerName2',
          'Customer Name 3' : 'customerName3',
          'Customer Name 4' : 'customerName4',
          'Customer Code' : {
            field: 'customerCode',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'DO Number' : {
            field: 'DOnumber',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'DO Date': 'Dodate',
          'Material':'material',
          'Quantity' : {
            field: 'quantity',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'SBU' :{
            field: 'phier',
            callback: (value) => {
              return ` ​${value}`
            }
          },
          'Return Reason' :'returnReason',
          'Return Status' :'returnStatus',
          'Return Number' :'flag',
        },
        downPage:0,
        dataDownload:0,
        returnToDownload:[],
        promptDownload: false,
        dataToDownload:[],
        dataForTable:[],
        isLoading: false,
        productHierarchy: [],
        type : "",
        typeToggle:true,
        fromMonth: null,
        toMonth: null,
        active: false,
        totalRowsDownload: null,
        noData: false,
      };
    },
    methods: {
      ...mapActions({
        getReturnReport: 'report/getReturn',
      }),
      async openDownloadModal(){
        this.downPage = 0
        this.dataDownload = 0
        this.returnToDownload = []
        this.promptDownload=true;
        var dataDownload=this.dataDownload
        let dataProcess = []
        let startMonth = this.fromMonth.getMonth()
        let startYear = this.fromMonth.getFullYear()
        let endMonth = this.toMonth.getMonth()
        let endYear = this.toMonth.getFullYear()

        await this.getReturnReport({
          startMonth, 
          startYear,
          endMonth,
          endYear,
        }).then((res) => {
          if (res.data.length === 0) {
            this.noData = true
          }
          dataProcess=res.data;
          this.returnToDownload.push(...dataProcess)
          this.returnToDownload = this.returnToDownload.map(item => {
            return {
              ...item,
              returnDate : this.dates(item.returnDate),
              Dodate : this.dates(item.Dodate)
            }
          })
          dataDownload += dataProcess.length
          this.dataDownload = dataDownload
          this.totalRowsDownload = dataProcess.length
        });  
      },

      updateToDateRange() {
        if (this.toMonth) {
          const maxAllowedDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth() + 12, 1);
          if (this.toMonth > maxAllowedDate) {
            this.toMonth = maxAllowedDate;
          }
        }
      },
      
      dates(date) {
        //console.info('date ',date)
        if (date==undefined||date==""){
          return ""
        }
        return dateFormat(date);
      },

      resetNoData() {
        this.noData = false;
      }
    },
  
    computed: {
      ...mapGetters({
        returnReport: 'report/return'
      }),
      disableFromDate() {
        const today = new Date();
        const minYear = 2023;
        const minMonth = today.setMonth(today.getMonth()); // Geser ke bulan depan

        return (date) => {
          return date.getFullYear() < minYear || date > minMonth;
        };
      },
      disableToDate() {
        const today = new Date();
        const minMonth = today.setMonth(today.getMonth()); // Geser ke bulan depan
        if (!this.fromMonth) return (date) => date > minMonth;
        const minToDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth(), 1);
        const maxToDate = new Date(this.fromMonth.getFullYear(), this.fromMonth.getMonth() + 12, 1);
        return (date) => date < minToDate || date > maxToDate || date > minMonth;
      },
      progressDownload () {
        let downloaded = this.returnToDownload.length
        let dataToDownload = this.totalRowsDownload

        return Math.ceil((downloaded/dataToDownload)*(100))
      },
      showCheckbox() {
        if (!this.fromMonth || !this.toMonth) return false;

        const fromDate = new Date(this.fromMonth);
        const toDate = new Date(this.toMonth);
        const minDate = new Date("2023-01-01");
        minDate.setHours(0, 0, 0, 0);
        const maxDate = new Date("2025-02-05");
        maxDate.setHours(0, 0, 0, 0);
        return fromDate >= minDate && toDate <= maxDate;
      },
    },
    watch: {
      selectMonthA(newVal) {
        this.tableMonthA = newVal;
      },
      selectMonthB(newVal) {
        this.tableMonthB = newVal;
      },
      toFilterProductYears(newVal) {
        this.tableExp = newVal;
      },
    },
    created() {
      document.title = 'Return Report | RSP';
    },
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
  }
  
  .controls {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .dropdown {
    padding: 10px;
    font-size: 16px;
  }
  
  .chart-container {
    margin-top: 20px;
  }
  
  </style>